import { defineAsyncComponent } from 'vue'

//////////////////////////////////////////////////////////////
// layouts
const VpBaseLayout = defineAsyncComponent(() => import('./layouts/VpBaseLayout.vue'))
import VpBase from './layouts/components/VpBase.vue'
// const VpBase = defineAsyncComponent(() => import('./layouts/components/VpBase.vue'))
const VpTemplateList = defineAsyncComponent(() => import('./templates/list/VpTemplateList.vue'))
const VpTemplateGrid = defineAsyncComponent(() => import('./templates/grid/VpTemplateGrid.vue'))
const VpFabButtons = defineAsyncComponent(() => import('./layouts/components/VpFabButtons.vue'))
//////////////////////////////////////////////////////////////

export {
  VpBaseLayout,
  VpBase,
  VpTemplateList,
  VpTemplateGrid,
  VpFabButtons
}
