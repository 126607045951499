import { miaps } from './miaps_hybrid_esm.js'

/**
 * 공통알림 함수
 * @param  data
 * Push Message의 경우 - data : {"code":"200", "type":"push", "res":
 * 								{"msg":"푸시메시지", "pid":"푸시ID", "tsk":"푸시업무ID", "rmk":"Text|http://miaps.thinkm.co.kr/miaps5/minkSvc?command=resfile&i=21&f=392x244_2.jpg|", "app":"", "frm":""}
 * 						}
 * Timer의 경우 - {"code":"200", "msg":"", "type":"timer", "res":"시간 및 메시지"}
 * Backgroud event의 경우 - {"code":"200", "msg":"", "type":"background", "res":""}
 * Foregroud evnet의 경우 - {"code":"200", "msg":"", "type":"foreground", "res":""}
 */
if (window._tmfg === undefined) {
  window._tmfg = true
} // foreground: true, background: false

export var miapsCallback = function (data) {
  if (data != null && data !== '') {
    let resValue = miaps.parse(data)
    let type = resValue.type

    if (window._tmdebug) console.log(miaps.beautify(resValue))

    if (type.toLowerCase() === 'push') {
      /* 푸시수신확인(읽음확인) 예 */
      console.log('pushValue.res.pid: ' + resValue.res.pid)
      miaps.miapsSvcSp(
        'com.thinkm.mink.connector.login.Device',
        'receivePush',
        'type=app&pushId=' + resValue.res.pid,
        function (data) {
          console.log('읽음 확인 완료')
        }
      )
      try {
        // miaps ui modal 표시
        var push_msg
        //and, ios 메시지 경로가 서로 다름
        if (miaps.isIOS()) {
          console.log('resValue.res.aps.alert: ' + resValue.res.aps.alert)
          push_msg = resValue.res.aps.alert
        } else {
          console.log('resValue.res.msg: ' + resValue.res.msg)
          push_msg = resValue.res.msg
        }
        alert(push_msg)
      } catch (e) {
        console.log(e.message)
      }
    } else if (type.toLowerCase() === 'foreground') {
      window._tmfg = true
    } else if (type.toLowerCase() === 'background') {
      window._tmfg = false
    } else if (type.toLowerCase() === 'session') {
      alert('notify.js session callback')
    } else if (type.toLowerCase() === 'shake') {
      // shake 재등록
      if (!miaps.isPC()) {
        miaps.mobile(
          {
            type: 'shake',
            param: ''
          },
          null
        )
      }

      if (!window._tmfg) return

      miaps.mobile(
        {
          type: 'toasts',
          param: { msg: '흔들기 콜백 호출' }
        },
        null
      )
    } else if (type.toLowerCase() === 'openurl') {
      // ex) miapshybrid://move?p=pages/service/deviceInfo.html
      // -->receive : { code:200, type:"OpenURL", res:"miapshybrid://move?p=pages/service/deviceInfo.html" }
      console.log('openurl')
      try {
        // move page ("miapsPage" must be loaded)
        //dmfd://move?p=pages/service/deviceInfo.html
        //let _link = resValue.res.substring(21); // pages/service/deviceInfo.html
        console.log(resValue.res)
        const parts = resValue.res.split('://move?p=')
        const _link = parts[1]
        console.log('go DeepLink')
        window._ncb.navigateByDeepLink(_link);
      } catch (e) {
        alert(e.message)
      }
    }
  }
}
