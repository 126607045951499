/*!
 * miaps hybrid v2.0 (Used ES6 Module)
 * Copyright thinkM, Inc. All rights reserved.
 *
 * This software is proprietary information of thinkM, Inc.
 * You shall not disclose such Confidential Information and shall use it
 * only in accordance with the terms of the license agreement you entered into with thinkM.
 */
import { miapsProp } from './miaps_property_esm.js'
import { miapsCallback } from './miaps_notify_esm.js'
import { miapsExtLib } from './miaps_extlib_esm.js'
import $ from 'jquery'
import axios from 'axios'

import request from '@/utils/request'

if (miapsProp === undefined) {
  console.log('miaps_property_esm.js is not imported.')
} // miaps_property.js 가 로드되지 않으면 return
if (window._tmdebug === undefined) {
  window._tmdebug = miapsProp.debug
} // true, false
if (window._miaps_runmode === undefined) {
  window._miaps_runmode = miapsProp.runMode
} // dev, real
window._usecontext = miapsProp.useContext // URL에 context root가 있으면 true, 없으면 false
var pathConfig = {
  myFileRoot: miapsProp.myFileRoot // 개인 프로젝트별로 재정의 (hybrid local path, Ex: /hybrid/)
}
window._pathConfig = pathConfig
var MiapsHybrid = {
  Version: '2.0.0',
  defServlet: '/minkSvc',
  mh_model: '',
  mh_os: '',
  maxLogLength: 2000,
  serverInfo: miapsProp.serverInfo, //	ex) {"url":"http://miaps.thinkm.co.kr/miaps6beta","chkPath": "/miapsdemo2/","runmode": "real"},{},..
  sendUrl: '',
  MobilePlatform: {
    Android: function () {
      return navigator.userAgent.match(/Android/i)
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i)
    },
    iPhone: function () {
      return navigator.userAgent.match(/iPhone|iPod/i)
    },
    iPad: function () {
      return navigator.userAgent.match(/iPad|Macintosh/i)
      //return navigator.userAgent.match(/iPad|Macintosh/i) && 'ontouchend' in document; // MiAPS Hybrid App은 Macintosh를 iPad로 바꿔서 통신함.
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPod|iPad/i)
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i)
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i)
    },
    any: function () {
      return (
        this.Android() ||
        this.BlackBerry() ||
        this.iOS() ||
        this.Opera() ||
        this.Windows()
      )
    }
  },
  NaviPlatform: {
    Windows: function () {
      return MiapsHybrid.checkPlatform('Win32')
    },
    Mac: function () {
      // MacIntel
      return MiapsHybrid.checkPlatform('Mac')
    },
    Android: function () {
      //
      //return navigator.platform.match(/Android|Linux armv|null/i);
      return MiapsHybrid.checkPlatform('Linux|null')
    },
    iOS: function () {
      return MiapsHybrid.checkPlatform('iPhone|iPad|iPod')
    }
  },
  checkPlatform: function (_pattern) {
    let _regexAllCase = new RegExp(_pattern, 'i')
    let _chkVal = false
    if (navigator.platform === undefined) {
      // deprecate after chrome 100
      if (_pattern === 'Win32' || _pattern === 'Mac') {
        if (
          window.chrome !== undefined ||
          window.safari !== undefined ||
          navigator.buildID !== undefined
        )
          _chkVal = true
      } else if (_pattern === 'Linux|null') {
        _chkVal = MiapsHybrid.MobilePlatform.Android()
      } else if (_pattern === 'iPhone|iPad|iPod') {
        _chkVal = MiapsHybrid.MobilePlatform.iOS()
      }
    } else {
      _chkVal = navigator.platform.match(_regexAllCase)
    }
    return _chkVal
  },
  isAndroid: function () {
    return MiapsHybrid.NaviPlatform.Android()
  },
  isIOS: function () {
    return MiapsHybrid.NaviPlatform.iOS()
  },
  isPC: function () {
    if (navigator.platform === undefined) {
      // deprecate after chrome 100
      // 출처:http://detectmobilebrowser.com
      let a = navigator.userAgent || navigator.vendor || window.opera
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
          a.substr(0, 4)
        )
      )
        return false
      else return true
    } else {
      if (MiapsHybrid.NaviPlatform.Windows() || MiapsHybrid.NaviPlatform.Mac())
        return true
      else return false
    }
  },
  isWebView: function () {
    /* 2021.04.27 chlee, MiAPS Hybrid WebView의 UserAgent에는 MiAPSHybrid가 포함되어 있다. */
    return navigator.userAgent.match(/MiAPSHybrid/i)
  },
  runBrowserMode: function () {
    //return MiapsHybrid.isPC() || !MiapsHybrid.isWebView();
    return MiapsHybrid.isPC()
  },
  /* 내부용 함수, 외부에서 호출하지 않습니다. */
  ajaxSvc: function (svc_name, send_data, cb_func) {
    let xhr = new XMLHttpRequest()
    xhr.onload = function () {
      if (xhr.status === 200 || xhr.status === 201) {
        if (cb_func != null) cb_func(xhr.response)
      } else {
        console.log(xhr.response)
        if (cb_func != null) cb_func(xhr.response)
      }
    }
    xhr.open(
      'post',
      window.location.protocol +
        '//' +
        window.location.host +
        _gContextNm +
        '/hybrid/' +
        svc_name
    )
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    if (typeof send_data == 'object') xhr.send($.param(send_data))
    else xhr.send(send_data)
  },
  /*자바스크립트의 Math.random 사용은 시큐어 코딩 가이드에서도 많이 지적되는 내용으로 다음과 같이 소스코드를 개선합니다. */
  mathRandom: function () {
    var array = new Uint32Array(3)
    window.crypto.getRandomValues(array)
    return function (x, y, z) {
      x = (171 * x) % 30269
      y = (172 * y) % 30307
      z = (170 * z) % 30323
      return (x / 30269.0 + y / 30307.0 + z / 30323.0) % 1.0
    }.apply(null, array)
  },
  makeCbFnNm: function (head) {
    return head + MiapsHybrid.mathRandom().toString(36).substring(2)
  },
  /**
   * MiAPS Player Version Check Script.<br>
   * Local에서 개발할 때는 파라메터와 콜백함수가 사용되지만 앱에서 호출 될 때는 무시됩니다.<br>
   * @param params platform_cd, sw_name (앱에서는 무시)
   * @param cb_func 콜백함수명 (앱에서는 무시)
   * <pre>
   * {@code
   * * Local테스트 예(브라우저)
   * MiapsHybrid.updateApp('platform_cd=2000012&sw_name=kr.co.miaps.mshop.dev', callbackfunction); // 브라우저 테스트
   * * 앱
   * MiapsHybrid.updateApp(null, null); // 기본
   * MiapsHybrid.updateApp('/minkSvc', null) // application.json의 serverurl에 /minkSvc같은 컨텍스트가 없는 버전을 사용할 경우.
   * }
   * </pre>
   */
  updateApp: function (params, cb_func) {
    if (MiapsHybrid.NaviPlatform.Windows() || MiapsHybrid.NaviPlatform.Mac()) {
      if (window._tmdebug) {
        console.log('call updateApp...')
      }
      var sendValue = {}
      if (
        params != null &&
        params.indexOf('platform_cd') > -1 &&
        params.indexOf('sw_name') > -1
      ) {
        sendValue.values = params
      } else {
        sendValue.values = null
      }
      if (window._tmdebug) {
        console.log(
          'http://' +
            window.location.hostname +
            ':' +
            window.location.port +
            _gContextNm +
            '/hybrid/updateApp.miaps'
        )
      }
      MiapsHybrid.ajaxSvc('updateApp.miaps', sendValue, cb_func)
    } else {
      // Linux armv, iP*
      /* Android */
      if (MiapsHybrid.MobilePlatform.Android()) {
        if (
          params != null &&
          params.indexOf('platform_cd') === -1 &&
          params.indexOf('sw_name') === -1
        ) {
          window.MiAPS.updateApp(params)
        } else {
          window.MiAPS.updateApp(MiapsHybrid.defServlet)
        }
        /* iPhone/iPad */
      } else if (
        MiapsHybrid.MobilePlatform.iPhone() ||
        MiapsHybrid.MobilePlatform.iPad()
      ) {
        var scheme = 'MiapsHybrid://mode=updateApp'
        if (
          params != null &&
          params.indexOf('platform_cd') === -1 &&
          params.indexOf('sw_name') === -1
        ) {
          scheme += '&pathname=' + encodeURIComponent(params)
        } else {
          scheme += '&pathname=' + encodeURIComponent(MiapsHybrid.defServlet)
        }
        window.webkit.messageHandlers.miapshybrid.postMessage(scheme)
      }
    }
  },

  /**
   * MiAPS Player SetDeviceInfo Script.<br>
   * 로그인 작업 후 miaps_user테이블에 유저 데이터가 입력되어 user_no가 발행되고 난 후 사용 합니다.<br>
   * userid, userpw, userno, groupid를 입력받아 MiAPS통신 프로토콜에 삽입하여, 이후 모든 통신에 해당 정보를 삽입 합니다.<br>
   * 이 후 라이선스 체크 및 SaveDeviceInfo를 실행하여 푸시정보등을 DB에 저장합니다.<br>
   * Local테스트 시는 ${mink.home}/conf/miaps.savedeviceinfo.properties의 데이터를 사용 해 SaveDeviceInfo를 실행 합니다.
   * 콜백함수는 Local 테스트에서만 호출 되고 앱에서는 무시 됩니다.
   * @param userid 유저ID
   * @param userpw 유저PASSWORD
   * @param userno 유저NO (MiAPS발행 Sequence)
   * @param groupid 그룹ID
   * @param cb_func 콜백함수명 (앱에서는 무시) 또는 minkNewUserName
   * @pathname
   */
  setDeviceInfo: function () {
    var userid = '',
      userpw = '',
      userno = '',
      groupid = '',
      cb_func = '',
      pathname = '',
      minkNewUserName = ''
    userid = arguments[0]
    userpw = arguments[1]
    userno = arguments[2]
    groupid = arguments[3]

    if (arguments[4] == null || arguments[4] === '') {
      cb_func = null
    } else if (typeof arguments[4] === 'function') {
      // callback function
      cb_func = arguments[4]
    } else if (arguments[4] !== '' && typeof arguments[4] === 'string') {
      // minkNewUserName
      minkNewUserName = arguments[4]
    }

    if (arguments.length === 6) {
      pathname = arguments[5]
    }

    if (MiapsHybrid.NaviPlatform.Windows() || MiapsHybrid.NaviPlatform.Mac()) {
      if (window._tmdebug) {
        console.log(
          'call setDeviceInfo: userid-' +
            userid +
            ', userpw-' +
            userpw +
            ', userno-' +
            userno +
            ', groupid-' +
            groupid +
            ', minkNewUserName-' +
            minkNewUserName
        )
      }

      var sendValue = {
        userid: userid,
        userpw: userpw,
        userno: userno,
        groupid: groupid
      }
      if (arguments.length === 6) {
        sendValue.pathname = pathname
      }

      if (cb_func == null || typeof cb_func === 'function') {
        // callback function
        MiapsHybrid.ajaxSvc('setDeviceInfo.miaps', sendValue, cb_func)
      } else if (minkNewUserName !== '') {
        // minkNewUserName
        sendValue.minkNewUserName = minkNewUserName
        MiapsHybrid.ajaxSvc('setDeviceInfo.miaps', sendValue, null)
      }
    } else {
      /* Android */
      if (MiapsHybrid.MobilePlatform.Android()) {
        if (pathname != null && pathname !== '') {
          window.MiAPS.setDeviceInfo(
            pathname,
            encodeURIComponent(userid),
            encodeURIComponent(userpw),
            userno,
            encodeURIComponent(groupid),
            encodeURIComponent(minkNewUserName)
          )
        } else {
          window.MiAPS.setDeviceInfo(
            MiapsHybrid.defServlet,
            encodeURIComponent(userid),
            encodeURIComponent(userpw),
            userno,
            encodeURIComponent(groupid),
            encodeURIComponent(minkNewUserName)
          )
        }
        /* iPhone/iPad */
      } else if (
        MiapsHybrid.MobilePlatform.iPhone() ||
        MiapsHybrid.MobilePlatform.iPad()
      ) {
        var scheme = 'MiapsHybrid://mode=setDeviceInfo'
        scheme +=
          '&userid=' +
          encodeURIComponent(userid) +
          '&userpw=' +
          encodeURIComponent(userpw) +
          '&userno=' +
          userno +
          '&groupid=' +
          encodeURIComponent(groupid)
        if (pathname != null && pathname !== '') {
          scheme += '&pathname=' + encodeURIComponent(pathname) // 패키지명.json(application.json)파일내에 serverurl에 minkSvc를 사용하지 않을 경우 pathname을 받아서 serverurl+pathname으로 호출
        } else {
          scheme += '&pathname=' + encodeURIComponent(MiapsHybrid.defServlet)
        }
        scheme += '&minkNewUserName=' + encodeURIComponent(minkNewUserName)

        window.webkit.messageHandlers.miapshybrid.postMessage(scheme)
      }
    }
  },

  /**
   * MiAPS 서버에 작성한 함수 호출 Script.<br>
   *
   * @param classname class의 전체이름 (예: com.mink.connectors.xxxx.login.LoginMan)
   * @param methodname 위 class에서 호출 할 method명
   * @param target MiAPS 어드민센터 설정&gt;업무구분 설정에 정의한 업무구분
   * @param params 호출 할 함수로 전달할 파라메터
   * @param cb_func_nm 앱에서 호출 할 콜백함수, String
   * @param cb_func 웹에서 호출 할 콜백함수, Object
   * @param pathname /minkSvc이외의 사용할 서블릿명을 입력. 해당명은 web.xml에 등록되어 있어야 합니다.
   */
  miapsSvc: function () {
    var classname = '',
      methodname = '',
      target = '',
      params = '',
      cb_func_nm = '',
      cb_func = '',
      pathname = MiapsHybrid.defServlet,
      headerObj = ''
    classname = arguments[0]
    methodname = arguments[1]
    target = arguments[2]
    params = arguments[3]
    cb_func_nm = arguments[4]
    cb_func = arguments[5]

    if (arguments.length === 7) {
      if (typeof (arguments[6] === 'string')) {
        pathname = arguments[6]
      } else if (typeof (arguments[6] === 'object')) {
        headerObj = arguments[6]
      }
    } else if (arguments.length === 8) {
      pathname = arguments[6]
      headerObj = arguments[7]
    }

    var header = ''
    if (headerObj != null && headerObj !== '' && typeof headerObj == 'object') {
      header = MiapsHybrid.serialize(headerObj)
    }

    var sendParam = MiapsHybrid.serialize(params)

    if (MiapsHybrid.runBrowserMode()) {
      if (window._tmdebug)
        console.log(
          'classname:' +
            classname +
            ', methodname:' +
            methodname +
            ', target:' +
            target +
            ', params:' +
            params +
            ', cbfunc:' +
            cb_func
        )

      var sendValue = {
        classname: classname,
        methodname: methodname,
        target: target,
        params: sendParam,
        header: header,
        pathname: pathname
      }
      MiapsHybrid.ajaxSvc('miapsSvc.miaps', sendValue, cb_func)
    } else {
      if (MiapsHybrid.isAndroid()) {
        if (MiapsHybrid.sendUrl !== '') {
          // url을 직접 전달 할 경우 추가
          // pathname, classname, methodname, target, params, header, callback
          window.MiAPS.runMiaps(
            pathname,
            classname,
            methodname,
            target,
            encodeURIComponent(sendParam),
            encodeURIComponent(header),
            encodeURIComponent(cb_func_nm),
            encodeURIComponent(MiapsHybrid.sendUrl)
          )
        } else {
          window.MiAPS.runMiaps(
            pathname,
            classname,
            methodname,
            target,
            encodeURIComponent(sendParam),
            encodeURIComponent(header),
            encodeURIComponent(cb_func_nm)
          )
        }
      } else if (MiapsHybrid.isIOS()) {
        let scheme = {
          mode: 'runMiaps',
          classname: encodeURIComponent(classname),
          methodname: encodeURIComponent(methodname),
          target: encodeURIComponent(target),
          pathname: encodeURIComponent(sendParam),
          params: encodeURIComponent(sendParam),
          cb_fun: encodeURIComponent(cb_func_nm),
          header: encodeURIComponent(JSON.stringify(headerObj))
        }
        if (MiapsHybrid.sendUrl !== '') {
          // url을 직접 전달 할 경우 추가
          scheme.serverurl = encodeURIComponent(MiapsHybrid.sendUrl)
        }
        window.webkit.messageHandlers.miapshybrid.postMessage(scheme)
      }
    }
  },
  miapsSvcSp: function () {
    // 2020.02.02 add header (header is object type)
    var classname = '',
      methodname = '',
      params = '',
      pathname = '',
      callback = '',
      iframeid = '',
      header = ''
    classname = arguments[0]
    methodname = arguments[1]
    params = arguments[2]

    if (typeof arguments[3] === 'function') {
      callback = arguments[3]
    } else if (typeof arguments[3] === 'string') {
      pathname = arguments[3]
      callback = arguments[4]
    } else {
      // 4번째 파라메터는 pathname또는 callback function이여야 합니다. object등 다른 타입이면 에러 입니다.
      alert('Invalid parameter')
      return
    }
    if (arguments.length === 5) {
      // class, method, params, path, callback
      // class, method, params, callback, iframeid
      // class, method, params, callback, header
      if (typeof arguments[3] === 'function') {
        if (typeof arguments[4] === 'string') {
          iframeid = arguments[4]
        } else if (typeof arguments[4] === 'object') {
          header = arguments[4]
        }
      }
    } else if (arguments.length === 6) {
      // class, method, params, path, callback, iframeid
      // class, method, params, path, callback, header
      // class, method, params, callback, iframeid, header
      if (typeof arguments[3] === 'function') {
        iframeid = arguments[4]
        header = arguments[5]
      } else if (typeof arguments[3] === 'string') {
        if (typeof arguments[5] === 'string') {
          iframeid = arguments[5]
        } else if (typeof arguments[5] === 'object') {
          header = arguments[5]
        }
      }
    } else if (arguments.length === 7) {
      iframeid = arguments[5]
      header = arguments[6]
    }

    if (header !== '' && typeof header != 'object') {
      alert('Invalid parameter (header is object type)')
      return
    }

    if (pathname == null || pathname === '') {
      pathname = MiapsHybrid.defServlet
    }

    var _className = classname // 'com.mink.connectors.{mink.profile}' + classname
    var _targetName = _className + '.' + methodname
    //var sendParam = MiapsHybrid.serialize(params);

    if (callback == null || callback === '') {
      MiapsHybrid.miapsSvc(
        _className,
        methodname,
        _targetName,
        params,
        '',
        null,
        pathname,
        header
      )
    } else {
      //if (window._tmdebug) { console.log(callback.name); }
      if (callback.name == null || callback.name === '') {
        // 콜백명이 없으면 임시로 만든다.
        var tmpCallbackNm = MiapsHybrid.makeCbFnNm('_miapsTmpCb')
        if (window._tmdebug) {
          console.log('callback : ' + tmpCallbackNm)
        }
        window[tmpCallbackNm] = callback
        if (iframeid != null && iframeid !== '') {
          var iframeCallbackNm =
            'document.getElementById("' +
            iframeid +
            '").contentWindow.' +
            tmpCallbackNm
          MiapsHybrid.miapsSvc(
            _className,
            methodname,
            _targetName,
            params,
            iframeCallbackNm,
            window[tmpCallbackNm],
            pathname,
            header
          )
        } else {
          MiapsHybrid.miapsSvc(
            _className,
            methodname,
            _targetName,
            params,
            tmpCallbackNm,
            window[tmpCallbackNm],
            pathname,
            header
          )
        }
      } else {
        window[callback.name] = callback
        MiapsHybrid.miapsSvc(
          _className,
          methodname,
          _targetName,
          params,
          callback.name,
          window[callback.name],
          pathname,
          header
        )
      }
    }
  },
  // 통신 시 파라메터를 object를 사용, 2022.02.17 chlee
  miapsSvcEx: function (obj, cb_func) {
    // param:object, callback:function
    if (typeof obj != 'object') {
      if (window._tmdebug) {
        console.log('전달 파라메터는 object type이여야 합니다.')
      }
      return
    }
    /* {
				url: '',			// optional
				class: '',			// fixed
				method : '',		// fixed
				target: ''			// optional
				path: '/minkSvc'	// optional
				ifarmeid: ''		// optional
				headers : {},		// optional
				params : {}			// optional
			}
			*/
    var sendurl,
      classname,
      methodname,
      target,
      params,
      pathname,
      iframeid,
      header,
      tmpCallbackNm = ''
    if (cb_func != null || cb_func !== '') {
      if (cb_func.name == null || cb_func.name === '') {
        // 콜백명이 없으면 임시로 만든다.
        tmpCallbackNm = MiapsHybrid.makeCbFnNm('_miapsTmpCb')
        if (window._tmdebug) {
          console.log('callback : ' + tmpCallbackNm)
        }
        window[tmpCallbackNm] = cb_func
        if (
          obj.hasOwnProperty('ifarmeid') &&
          iframeid != null &&
          iframeid !== ''
        ) {
          tmpCallbackNm =
            'document.getElementById("' +
            iframeid +
            '").contentWindow.' +
            tmpCallbackNm
        }
      } else {
        tmpCallbackNm = cb_func.name
        window[tmpCallbackNm] = cb_func
      }
    }
    sendurl = obj.hasOwnProperty('url') ? obj.url : ''
    classname = obj.class
    methodname = obj.method
    target = obj.hasOwnProperty('target') ? obj.target : ''
    pathname = obj.hasOwnProperty('path') ? obj.path : '/minkSvc'
    params = obj.hasOwnProperty('params')
      ? MiapsHybrid.serialize(obj.params)
      : ''

    if (obj.hasOwnProperty('headers')) {
      if (
        obj.headers != null &&
        obj.headers !== '' &&
        typeof obj.headers == 'object'
      ) {
        header = MiapsHybrid.serialize(obj.headers)
      } else {
        header = ''
      }
    }

    if (MiapsHybrid.runBrowserMode()) {
      var sendValue = {
        sendurl: sendurl,
        classname: classname,
        methodname: methodname,
        target: target,
        params: params,
        header: header,
        pathname: pathname
      }
      MiapsHybrid.ajaxSvc('miapsSvc.miaps', sendValue, window[tmpCallbackNm])
    } else {
      if (MiapsHybrid.isAndroid()) {
        if (MiapsHybrid.sendUrl !== '') {
          // url을 직접 전달 할 경우 추가
          // pathname, classname, methodname, target, params, header, callback
          window.MiAPS.runMiaps(
            pathname,
            classname,
            methodname,
            target,
            encodeURIComponent(params),
            encodeURIComponent(header),
            encodeURIComponent(tmpCallbackNm),
            encodeURIComponent(MiapsHybrid.sendUrl)
          )
        } else {
          window.MiAPS.runMiaps(
            pathname,
            classname,
            methodname,
            target,
            encodeURIComponent(params),
            encodeURIComponent(header),
            encodeURIComponent(tmpCallbackNm)
          )
        }
      } else if (MiapsHybrid.isIOS()) {
        let scheme = {
          mode: 'runMiaps',
          classname: encodeURIComponent(classname),
          methodname: encodeURIComponent(methodname),
          target: encodeURIComponent(target),
          pathname: encodeURIComponent(params),
          params: encodeURIComponent(params),
          cb_fun: encodeURIComponent(tmpCallbackNm),
          header: encodeURIComponent(JSON.stringify(header))
        }
        if (MiapsHybrid.sendUrl !== '') {
          // url을 직접 전달 할 경우 추가
          scheme.serverurl = encodeURIComponent(MiapsHybrid.sendUrl)
        }
        window.webkit.messageHandlers.miapshybrid.postMessage(scheme)
      }
    }
  },

  // 데이터 관련 함수
  serialize: function (data) {
    var json = null
    if (typeof data === 'string') {
      // serialize string or json string
      if (data.startsWith('{')) {
        // json string
        json = JSON.parse(data)
      } else {
        // key=value& text type, key={json string}
        return data
      }
    } else if (typeof data === 'object') {
      // object
      json = data
    } else {
      if (window._tmdebug) {
        console.log('지원하지 않는 타입 입니다.')
      }
      return ''
    }

    var returnParams = ''

    //for (var key in json) {
    //	returnParams += (returnParams == "") ? key + "=" + encodeURIComponent(json[key]) : "&" + key + "=" + encodeURIComponent(json[key]);
    //}
    if (typeof json === 'object') {
      returnParams = $.param(json)
      returnParams = returnParams.replace(/(%5B)/g, '[').replace(/(%5D)/g, ']')
    } else {
      returnParams = json
    }

    return returnParams
  },

  /**
   * HTTP Client Request<br>
   * @param config
   * @param cb_func 콜백함수 전체
   * @returns 콜백함수 명
   */
  httpSvc: function (config, cb_func) {
    /* config = {
			 	url:"",
			 	header: {},
				param: "key=value&key=value",
				encrypt: true/false (optional: default is false) since 2022.02.09 
				callback:""
			 */
    var sendConfig = {
      url: config.url
    }

    if (config.hasOwnProperty('header')) {
      sendConfig.header = config.header
    }

    if (typeof config.param == 'object') {
      function keyToLowercase(tmpObj) {
        for (var key in tmpObj) {
          if (key.toLocaleLowerCase() !== key) {
            tmpObj[key.toLocaleLowerCase()] = tmpObj[key]
            delete tmpObj[key]
          }
        }
      }
      // key name to lowcase
      keyToLowercase(config.header)

      if (
        config.header.hasOwnProperty('content-type') &&
        config.header['content-type'].indexOf('application/json') > -1
      ) {
        sendConfig.param = JSON.stringify(config.param)
      } else {
        sendConfig.param = $.param(config.param)
      }
    } else {
      sendConfig.param = config.param
    }

    if (cb_func) {
      // object callback 함수는 있고 config.callback이 없으면 임시로 만든다.
      if (!config.hasOwnProperty('callback')) {
        if (cb_func.name == null || cb_func.name === '') {
          // 콜백명이 없으면 임시로 만든다.
          var tmpCallbackNm = MiapsHybrid.makeCbFnNm('_httpTmpCb')
          if (window._tmdebug) {
            console.log('callback : ' + tmpCallbackNm)
          }
          window[tmpCallbackNm] = cb_func
          sendConfig.callback = tmpCallbackNm
        } else {
          window[cb_func.name] = cb_func
          sendConfig.callback = cb_func.name
        }
      } else {
        sendConfig.callback = config.callback
      }
    } else {
      sendConfig.callback = ''
    }

    if (MiapsHybrid.runBrowserMode()) {
      MiapsHybrid.ajaxSvc('httpSvc.miaps', sendConfig, cb_func)
    } else {
      var configJsonStr = JSON.stringify(sendConfig)
      /* Android */
      if (MiapsHybrid.MobilePlatform.Android()) {
        window.MiAPS.runHttp(encodeURIComponent(configJsonStr))

        /* iPhone/iPad */
      } else if (
        MiapsHybrid.MobilePlatform.iPhone() ||
        MiapsHybrid.MobilePlatform.iPad()
      ) {
        var scheme =
          'MiapsHybrid://mode=runHttp&param=' +
          encodeURIComponent(configJsonStr)
        window.webkit.messageHandlers.miapshybrid.postMessage(scheme)
      }
    }
  },

  /**
   * HTTP Client Request<br>
   * @param config
   * @param cb_func 콜백함수 전체
   * @returns 콜백함수 명
   */
  axiosSvc: function (config, cb_func) {
    /* [APP]
				url:"",
			 	header: {},
				param: "key=value&key=value",
				encrypt: true/false (optional: default is false) since 2022.02.09 
				callback:""
			   [AXIOS]
			   	url:"",
			   	headers: {},
			   	data: {} or "key=value&key=value",
			 */
    var sendConfig = {
      url: config.url
    }

    if (config.hasOwnProperty('header')) {
      sendConfig.headers = config.header
    }

    if (typeof config.param == 'object') {
      function keyToLowercase(tmpObj) {
        for (var key in tmpObj) {
          if (key.toLocaleLowerCase() !== key) {
            tmpObj[key.toLocaleLowerCase()] = tmpObj[key]
            delete tmpObj[key]
          }
        }
      }
      // key name to lowcase
      keyToLowercase(config.header)

      if (
        config.header.hasOwnProperty('content-type') &&
        config.header['content-type'].indexOf('application/json') > -1
      ) {
        sendConfig.param = JSON.stringify(config.param)
      } else {
        sendConfig.param = $.param(config.param)
      }
    } else {
      sendConfig.param = config.param
    }

    if (cb_func) {
      // object callback 함수는 있고 config.callback이 없으면 임시로 만든다.
      if (!config.hasOwnProperty('callback')) {
        if (cb_func.name == null || cb_func.name === '') {
          // 콜백명이 없으면 임시로 만든다.
          let tmpCallbackNm = MiapsHybrid.makeCbFnNm('_httpTmpCb')
          window[tmpCallbackNm] = cb_func
          sendConfig.callback = tmpCallbackNm
        } else {
          window[cb_func.name] = cb_func
          sendConfig.callback = cb_func.name
        }
      } else {
        sendConfig.callback = config.callback
      }
    } else {
      sendConfig.callback = ''
    }

    if (MiapsHybrid.runBrowserMode()) {
      axios({
        method: 'post',
        url: sendConfig.url,
        data: config.param, // sendConfig.param
        headers: config.header
      }).then(function (data) {
        cb_func(data)
      })
    } else {
      var configJsonStr = JSON.stringify(sendConfig)
      /* Android */
      if (MiapsHybrid.MobilePlatform.Android()) {
        window.MiAPS.runHttp(encodeURIComponent(configJsonStr))

        /* iPhone/iPad */
      } else if (
        MiapsHybrid.MobilePlatform.iPhone() ||
        MiapsHybrid.MobilePlatform.iPad()
      ) {
        let scheme
        if (MiapsHybrid.iosSendType == 'json') {
          scheme = {
            mode: 'runHttp',
            param: sendConfig
          }
        } else {
          scheme =
            'MiapsHybrid://mode=runHttp&param=' +
            encodeURIComponent(configJsonStr)
        }
        window.webkit.messageHandlers.miapshybrid.postMessage(scheme)
      }
    }
  },

  /**
		 * SCAN, DocView, Bluetooth, Address, camera등등 모바일기기 내에 접근할 때 사용하는 함수
		 * @param config App에 전달할 json type value
		 * @code{
		   {
		 	  "type" : "DOCVIEW",
		 	  "param" : {
		 	     "A":"1",
		 	     "B":"2"
		 	  },
		 	  "callback" : "callbackname"
		 	}
		  }
		 * 		  type - SCAN: 
		 *        type - DOCVIEW: server_url | doc_url | D(or F) | cachetime
		 *        type - SESSION: session time체크. 호출 후 등록한 콜백함수로 결과리턴(세센타임 끝인지, 아닌지 또는 시간)
		 *        type - GEOLOCATION: 위치정보 조회. 호출 후 등록한 콜백함수로 결과리턴
		 *        type - SECURE: 단말 지문인식 또는 비밀번호 입력 창 호출
		 * @param cb_func_nm 앱에서 호출 할 콜백함수, String
		 * @param cb_func 웹에서 호출 할 콜백함수, Object
		 */
  mobilePC: function (config, cb_func) {
    var resConfig = {
      code: 200,
      type: config.type,
      param: config.param,
      res: ''
    }

    var configType = config.type.toLowerCase()
    if (configType === 'geolocation') {
      /* Html5 위치정보 취득 사용 */
      navigator.geolocation.getCurrentPosition(
        function (data) {
          resConfig.res = data.coords.latitude + ';' + data.coords.longitude
          cb_func(JSON.stringify(resConfig))
        },
        function (data) {
          resConfig.code = '500'
          resConfig.res = data.message
          cb_func(JSON.stringify(resConfig))
        }
      )
    } else if (
      configType === 'scan' ||
      configType === 'barcode' ||
      configType === 'qrcode' ||
      configType === 'appid' ||
      configType === 'finger' ||
      configType === 'version' ||
      configType === 'deviceid' ||
      configType === 'bundleid' ||
      configType === 'istablet' ||
      configType === 'devicemodel' ||
      configType === 'platformversion' ||
      configType === 'camera' ||
      configType === 'gallery' ||
      configType === 'capture' ||
      configType === 'isinstalled' ||
      configType === 'network' ||
      configType === 'deviceinfo' ||
      configType === 'extlib'
    ) {
      if (configType === 'extlib') {
        try {
          let extRes = miapsExtLib(resConfig.param)
          if (cb_func) cb_func(extRes)
        } catch (e) {
          console.log(e)
          alert('miapsExtLib가 설정되어 있지 않습니다.')
        }
      } else if (
        configType === 'camera' ||
        configType === 'gallery' ||
        configType === 'capture' ||
        configType === 'isinstalled' ||
        configType === 'loadcontact' ||
        configType === 'finger' ||
        configType === 'deviceinfo'
      ) {
        resConfig.res = config.res // 입력된 값으로 테스트 할 수 있도록 config의 res를 res에 그대로 넣어준다.
        console.log(resConfig)
        cb_func(JSON.stringify(resConfig))
      } else {
        resConfig.res = config.param // 입력된 값으로 테스트 할 수 있도록 param을 res에 그대로 넣어준다.
        cb_func(JSON.stringify(resConfig))
      }
    } else if (configType === 'language') {
      if (navigator.language) {
        resConfig.res = navigator.language
      } else {
        resConfig.code = '500'
        resConfig.res = 'navigator.language를 지원하지 않는 브라우저입니다.'
      }

      if (window._tmdebug) {
        console.log(resConfig)
      }
      cb_func(JSON.stringify(resConfig))
    } else if (configType === 'savevalue') {
      // 웹에서는 localstoage를 사용한다.
      let resText = 'Success'
      if (typeof config.param == 'object') {
        let keys = Object.keys(config.param)
        for (let idx in keys) {
          console.log('key=' + keys[idx] + ',  data=' + config.param[keys[idx]])
          try {
            localStorage.setItem(keys[idx], config.param[keys[idx]])

            // PC에서 getKeys에서 사용할 용도로 hSaveValueKeys에 key값을 저장해 놓는다. 2023.05.26
            let _hSvKeys = localStorage.getItem('save-value-keys')
            if (_hSvKeys == null || _hSvKeys === 'undefined') {
              _hSvKeys = []
              _hSvKeys.push(keys[idx])
              localStorage.setItem('save-value-keys', JSON.stringify(_hSvKeys))
            } else {
              _hSvKeys = JSON.parse(_hSvKeys) // chage array
              // 이미 있는 키인지 확인
              let bSame = false
              for (let i = 0; i < _hSvKeys.length; i++) {
                if (_hSvKeys[i] === keys[idx]) {
                  bSame = true
                  break
                }
              }
              if (!bSame) {
                _hSvKeys.push(keys[idx])
                localStorage.setItem(
                  'save-value-keys',
                  JSON.stringify(_hSvKeys)
                )
              }
            }
          } catch (e) {
            if (MiapsHybrid.isQuotaExceeded(e)) {
              // Storage full, maybe notify user or do some clean-up
              resConfig.code = e.code
              resText = 'Storage full, maybe notify user or do some clean-up'
              break
            }
          }
        }
      } else if (typeof config.param == 'string') {
        localStorage.setItem('tempKey', config.param)
      }
      resConfig.res = resText // 입력된 값으로 테스트 할 수 있도록 param을 res에 그대로 넣어준다.
      cb_func(JSON.stringify(resConfig))
    } else if (configType === 'loadvalue') {
      // 웹에서는 localstoage를 사용한다.
      let tmpRes = {}

      /*if (config.param.constructor == Object) { // ex) {"id":"", "pw":""}  앱에서 지원하지 않아 삭제
					var keys = Object.keys(config.param);
					console.log(keys);
					for (var idx in keys) {
						console.log("key="+keys[idx]);
						tmpRes[keys[idx]] = localStorage.getItem(keys[idx]);							
					}
				} else*/ if (config.param.constructor === Array) {
        for (let idx in config.param) {
          //console.log("key="+config.param[idx]);
          tmpRes[config.param[idx]] = localStorage.getItem(config.param[idx])
        }
      } /* else if (typeof config.param == 'string') { // ex) ["id","pw"](복수) or id(단일)   앱에서 지원하지 않아 삭제
					var tmpParam = config.param.replace(/\"/g, "").replace(/\[/g, "").replace(/\]/g, "").trim(); 
					var keys = tmpParam.split(',');
					for(var idx in keys) {
						console.log("key="+keys[idx].trim());
						tmpRes[keys[idx].trim()] = localStorage.getItem(keys[idx].trim());
					}
				}*/
      resConfig.res = tmpRes
      cb_func(JSON.stringify(resConfig))
    } else if (configType === 'getkeys') {
      // 웹에서는 localstoage를 사용한다.
      // savevalue로 저장할 때 사용한 모든  key 를 array로 리턴한다. 2022.06.17 - savevalue로 저장한 것 이외의 모든 localStorage값이 나오므로 삭제.
      /*let tmpRes = [];
				for (let i = 0; i < localStorage.length; i++) {
					let key   = localStorage.key(i);
					tmpRes.push(key);					
				}
				resConfig.res = tmpRes;
				*/
      // savevalue로 저장할 때 사용한 모든  key 를 array로 리턴한다 2023.05.26
      resConfig.res = JSON.parse(localStorage.getItem('save-value-keys'))
      cb_func(JSON.stringify(resConfig))
    } else if (configType === 'clearallvalue') {
      // savevalue로 저장한 모든 데이터를 삭제 합니다. 2020.02.27 chlee
      // getkeys로 가져온 key값만 삭제 하도록 수정. 2023.06.01 (모두 삭제하면 페이지관련 정보도 삭제 되므로..)
      let keyArray = JSON.parse(localStorage.getItem('save-value-keys'))
      if (keyArray) {
        keyArray.push('save-value-keys')
        for (let i = 0; i < keyArray.length; i++) {
          localStorage.removeItem(keyArray[i])
        }
      }
      //localStorage.clear();
      resConfig.res = 'success'
      cb_func(JSON.stringify(resConfig))
    } else if (configType === 'platform') {
      resConfig.res = 'unknown'
      if (MiapsHybrid.MobilePlatform.Android()) {
        resConfig.res = 'android'
      } else if (
        MiapsHybrid.MobilePlatform.iPhone() ||
        MiapsHybrid.MobilePlatform.iPad()
      ) {
        resConfig.res = 'ios'
      }
      cb_func(JSON.stringify(resConfig))
    } else if (configType === 'timezone') {
      var d = new Date()
      var n = d.getTimezoneOffset()
      n = (n / 60) * -1
      var gmt = 'GMT'
      if (n !== 0) {
        gmt += n > 0 ? ' +' : ' '
        gmt += n
      }
      resConfig.res = gmt
      cb_func(JSON.stringify(resConfig))
    } else if (configType === 'settabtitle') {
      //if (typeof config.param == 'object') {
      if (config.param.constructor === Object) {
        // ex) {"tabid":"", "title":""}
        document.title = config.param['title']
      }
    } else if (
      configType === 'filelist' ||
      configType === 'filecontents' ||
      configType === 'fileupload' ||
      configType === 'filedelete' ||
      configType === 'filedownload' ||
      configType === 'encrypt' ||
      configType === 'decrypt' ||
      configType === 'deviceip' ||
      configType === 'applicationinfo'
    ) {
      config.pathconfig = window._pathConfig
      let configJsonStr = JSON.stringify(config)
      var sendValue = {
        values: configJsonStr
      }
      MiapsHybrid.ajaxSvc('mobile.miaps', sendValue, cb_func)
    } else if (configType === 'toasts') {
      var toastMsg = ''
      if (config.param.constructor === Object) {
        toastMsg = config.param['msg']
      }
      new window.Android_Toast({ content: toastMsg })
    } else if (configType === 'newbrowser') {
      var newUrl = ''
      if (config.param.constructor === Object) {
        newUrl = config.param['url']
      }
      window.open(newUrl, '_blank')

      /*} else if (configType == 'applicationinfo') {
				$.ajax({
					url: 'http://'+ location.hostname + ':' + location.port + _gContextNm + _pathConfig.myFileRoot + '/application.json',
					async: true,
					type: 'get',
					data: '',
					dataType: 'json', // xml, json, script, html
					success: function (data) {
						if (cb_func != null) {
							resConfig.res = data;
							cb_func(JSON.stringify(resConfig));
						}
					},
					error: function (jsXHR) {
						if (cb_func != null) {
							cb_func(jsXHR);
						}
					}
				}); */
    } else if (configType === 'callnotify') {
      MiapsHybrid.notifyCallback(config.param)
    } else if (configType === 'shake') {
      MiapsHybrid.notifyCallback(config)
    } else {
      var _msg = '지원하지 않는 타입입니다.'
      if (cb_func != null) {
        resConfig.code = '500'
        resConfig.res = _msg
        cb_func(JSON.stringify(resConfig))
      } else {
        if (
          MiapsHybrid.NaviPlatform.Windows() ||
          MiapsHybrid.NaviPlatform.Mac()
        ) {
          console.log(_msg)
        } else {
          alert(_msg)
        }
      }
    }
  },

  mobile: function (config, cb_func) {
    if (config == null) {
      alert('config는 필수 입니다.')
    }

    if (cb_func) {
      // object callback 함수는 있고 config.callback이 없으면 임시로 만든다.
      if (!config.hasOwnProperty('callback')) {
        if (cb_func.name == null || cb_func.name === '') {
          // 콜백명이 없으면 임시로 만든다.
          var tmpCallbackNm = MiapsHybrid.makeCbFnNm('_nativeTmpCb', false)
          if (window._tmdebug) {
            console.log(
              JSON.stringify(config) + ', callback : ' + tmpCallbackNm
            )
          }
          window[tmpCallbackNm] = cb_func

          if (config.hasOwnProperty('iframeid') && config.iframeid !== '') {
            var iframeCallbackNm =
              'document.getElementById("' +
              config.iframeid +
              '").contentWindow.' +
              tmpCallbackNm
            config.callback = iframeCallbackNm
          } else {
            config.callback = tmpCallbackNm
          }
        } else {
          window[cb_func.name] = cb_func
          if (window._tmdebug) {
            console.log(JSON.stringify(config) + ', callback : ' + cb_func.name)
          }
          config.callback = cb_func.name
        }
      }
    } else {
      if (window._tmdebug) {
        console.log(JSON.stringify(config) + ', callback : none')
      }
      config.callback = ''
    }

    if (MiapsHybrid.runBrowserMode()) {
      MiapsHybrid.mobilePC(config, cb_func)
    } else {
      var configJsonStr = JSON.stringify(config)
      /* Android */
      if (MiapsHybrid.MobilePlatform.Android()) {
        window.MiAPS.mobile(encodeURIComponent(configJsonStr))
        /* iPhone/iPad */
      } else if (
        MiapsHybrid.MobilePlatform.iPhone() ||
        MiapsHybrid.MobilePlatform.iPad()
      ) {
        var scheme =
          'MiapsHybrid://mode=mobile&param=' + encodeURIComponent(configJsonStr)
        window.webkit.messageHandlers.miapshybrid.postMessage(scheme)
      }
    }
  },

  /**
   * Remote Query Script.<br>
   *
   * @param mode select (fetch) or execute
   * @param target connectoin bean Id
   * @param query SQL
   * @param cb_func_nm 앱에서 호출 할 콜백함수, String
   * @param cb_func 웹에서 호출 할 콜백함수, Object
   */
  querySvc: async function () {
    var mode = '',
      target = '',
      query = '',
      cb_func_nm = '',
      cb_func = ''
    mode = arguments[0]
    target = arguments[1]
    query = arguments[2]

    if (typeof arguments[3] === 'function') {
      cb_func = arguments[3]
    } else if (typeof arguments[3] === 'string') {
      cb_func_nm = arguments[3]
      cb_func = arguments[4]
    }

    if (cb_func) {
      // object callback 함수는 있고 config.callback이 없으면 임시로 만든다.
      if (cb_func.name == null || cb_func.name === '') {
        // 콜백명이 없으면 임시로 만든다.
        var tmpCallbackNm = MiapsHybrid.makeCbFnNm('_queryTmpCb')
        if (window._tmdebug) {
          console.log('callback : ' + tmpCallbackNm)
        }
        window[tmpCallbackNm] = cb_func
        cb_func_nm = tmpCallbackNm
      } else {
        window[cb_func.name] = cb_func
        cb_func_nm = cb_func.name
      }
    }

    if (MiapsHybrid.runBrowserMode()) {
      if (window._tmdebug) {
        console.log(
          'mode:' +
            mode +
            ', target:' +
            target +
            ', query:' +
            query +
            ', cb_func_nm:' +
            cb_func_nm +
            ', cbfunc:' +
            cb_func
        )
      }
      var sendValue = {
        mode: mode,
        target: target,
        query: query
      }
      //MiapsHybrid.ajaxSvc('querySvc.miaps', sendValue, cb_func);
      cb_func = await request({
        url: '/hybrid/querySvcAxios.miaps',
        params: sendValue
      })

      // console.log(cb_func)
      return cb_func
      /* MiapsHybrid.axiosSvc({
					url: 'http://localhost:8080/hybrid/querySvcAxios.miaps',
					param: sendValue,
					header : {
						"Content-Type":"application/x-www-form-urlencoded; charset=UTF-8"
					}
				}, cb_func); */
    } else {
      /* Android */
      if (MiapsHybrid.MobilePlatform.Android()) {
        window.MiAPS.runQuery(
          mode,
          encodeURIComponent(target),
          encodeURIComponent(query),
          encodeURIComponent(cb_func_nm)
        )
        /* iPhone/iPad */
      } else if (
        MiapsHybrid.MobilePlatform.iPhone() ||
        MiapsHybrid.MobilePlatform.iPad()
      ) {
        var scheme =
          'MiapsHybrid://mode=runQuery&type=' +
          encodeURIComponent(mode) +
          '&target=' +
          encodeURIComponent(target) +
          '&query=' +
          encodeURIComponent(query) +
          '&cb_func=' +
          encodeURIComponent(cb_func_nm)
        window.webkit.messageHandlers.miapshybrid.postMessage(scheme)
      }
    }
  },

  /**
   * id가 null이면 화면의 가운데에 wait커서 표시<br>
   * id가 있을 경우는 id의 bootstrap graphicon을 회전시킨다.<br>
   * @param id : element id
   * @param type : 'default', 'wait'
   * @param bg : true, false
   */
  cursor: function (id, type, bg) {
    if (id == null) {
      if (type == null || type === 'default') {
        $('#_miaps_cursor').remove()
        this.mask('off')
      } else {
        if ($('#_miaps_cursor').length <= 0) {
          var _top = $(window).height() / 2 + $(window).scrollTop()
          var _left = $(window).width() / 2
          $(document.body).append(
            "<div id='_miaps_cursor' class='icon-block' style = 'top:" +
              _top +
              '; left : ' +
              _left +
              "'></div>"
          )
        }

        /* class가 우선순위가 낮아서 style로 대체 
					$("#_miaps_cursor").css(
						{
							'top'	: ($(window).height() / 2) + $(window).scrollTop()
							,'left'	: ($(window).width() / 2)
						}
					);*/

        if (bg) {
          this.mask('on')
        }
      }
    } else {
      if (type == null || type === 'default') {
        var bkedClass = $('#hc_' + id).attr('class')
        $('#' + id).attr('class', bkedClass)
        $('#hc_' + id).remove()
        this.mask('off')
      } else if (type != null && type === 'wait') {
        var bkClass = $('#' + id).attr('class')
        var hiddenClass =
          "<input id='hc_" + id + "' type='hidden' class='" + bkClass + "'/>"
        $(document.body).append(hiddenClass)
        $('#' + id).attr('class', 'glyphicon glyphicon-refresh spinning')
        if (bg) {
          this.mask('on')
        }
      }
    }
  },

  mask: function (option) {
    // on, off
    if (option === 'on') {
      $(document.body).append("<div id='_miaps_mask'></div>")

      //화면의 높이와 너비를 구한다.
      let maskHeight = $(document).height()
      let maskWidth = $(window).width()

      //마스크의 높이와 너비를 화면 것으로 만들어 전체 화면을 채운다.
      $('#_miaps_mask').css({ width: maskWidth, height: maskHeight })

      //애니메이션 효과
      $('#_miaps_mask').fadeIn(300)
      $('#_miaps_mask').fadeTo('fast', 0.5)
    } else {
      $('#_miaps_mask').remove()
    }
  },

  loadScript: function (url, callback) {
    let scriptEl = document.createElement('script')
    scriptEl.type = 'text/javascript'
    // IE에서는 onreadystatechange를 사용
    scriptEl.onload = function () {
      callback()
    }
    scriptEl.src = url
    document.getElementsByTagName('head')[0].appendChild(scriptEl)
  },

  /**
   * MiAPS Hybrid App에서 Push를 수신할 경우(수신메시지를 클릭할 경우) 호출 되는 callback함수.
   * 개발자는 miaps_notify.js의 callback(data)에 코딩을 추가한다.
   */
  notifyCallback: function (data) {
    if (window._tmdebug)
      console.log(
        'notifyCallback(->miaps_hybrid.js(callback)) - ' +
          MiapsHybrid.beautify(data)
      )

    miapsCallback(data)
  },

  /**
   * data값이
   * @param data
   * @returns
   */
  parse: function (data) {
    var obj
    if (data !== '' && typeof data == 'string') {
      try {
        obj = JSON.parse(data)
      } catch (e) {
        console.log(e.name + '\n' + e.message)
        // Error HTML page..
        obj = JSON.parse(
          JSON.stringify({
            res: {
              code: 500,
              msg: 'error..(miaps.parse오류입니다. 결과가 404 html일 확율이 큽니다.)'
            }
          })
        ) // 원하는 형식으로 변경합니다.
      }
    } else {
      // typeof object는 그대로~
      obj = data
    }

    return obj
  },
  beautify: function (data) {
    try {
      if (typeof data == 'object') return JSON.stringify(data, null, 4)
      else if (typeof data == 'string')
        return JSON.stringify(JSON.parse(data), null, 4)
    } catch (e) {
      console.log(e)
    }
  },
  /**
   * 어드민센터 앱 현황 >  앱 매뉴관리에 등록한 메뉴 권한을 가져온다.
   * @ param roleId.ur - 역할그룹ID
   * @ param roleId.ug - 그룹ID
   * @ param roleId.ud - 사용자ID
   */
  getAppMenuRole(roleId) {
    MiapsHybrid.mobile({ type: 'appid', param: '' }, function (data) {
      var obj = MiapsHybrid.parse(data)
      roleId.pkgnm = obj.res
      MiapsHybrid.miapsSvcSp('', '', roleId, function (data) {
        var obj = MiapsHybrid.parse(data)
        sessionStorage.setItem('miaps_app_menu', obj.res)
      })
    })
  },
  /**
   * localStorage try/catch (Storage full check)
   */
  isQuotaExceeded: function (e) {
    let quotaExceeded = false
    if (e) {
      if (e.code) {
        switch (e.code) {
          case 22:
            quotaExceeded = true
            break
          case 1014:
            // Firefox
            if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
              quotaExceeded = true
            }
            break
          default:
            break
        }
      } else if (e.number === -2147024882) {
        // Internet Explorer 8
        quotaExceeded = true
      }
    }
    return quotaExceeded
  },

  sleep: function (milliseconds) {
    var start = new Date().getTime()
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break
      }
    }
  },

  getContextPath: function () {
    if (window._usecontext === true) {
      var hostIndex =
        window.location.href.indexOf(window.location.host) +
        window.location.host.length
      return window.location.href.substring(
        hostIndex,
        window.location.href.indexOf('/', hostIndex + 1)
      )
    } else {
      return ''
    }
  },

  /**
   * 동적으로 DOM 개체를 만들어 반환한다.
   * @param {any} parent 부모 개체 또는 selector. null이면 DOM에는 아직 붙지 않아 개체가 화면에 나타나지 않게됨.
   * @param {string} tag
   * @param {object} attrs
   * @param {string} text 개체 안에 넣을 텍스트
   * @returns
   */
  dom: function (parent, tag, attrs, text) {
    var el = document.createElement(tag)
    if (attrs)
      for (var key in attrs) {
        var val = attrs[key]
        if (typeof val === 'object') {
          var x = el[key]
          for (var k in val) x[k] = val[k]
        } else {
          el[key] = val
        }
      }

    if (text) el.appendChild(document.createTextNode(text))

    if (typeof parent === 'string') parent = document.querySelector(parent)
    if (parent) parent.appendChild(el)

    return el
  },
  searchDebugKeyup: function () {
    // 검색어가 없을 경우 전체 로그 표시
    var keyword = document.getElementById('miaps-search-keyword').value.trim()
    $('#miaps-search-clear').toggle(Boolean(keyword))

    if (keyword == null || keyword.length === 0) {
      $('#_miaps_console > div').not('#miaps-debug-search-box').show()
    } else {
      $('#_miaps_console > div').not('#miaps-debug-search-box').hide()
      var temp = $(
        "#_miaps_console > div > div > span:nth-child(2n+2):containsIN('" +
          keyword +
          "')"
      )
      $(temp).parent().parent().show()
    }
  },
  searchDebugClear: function () {
    // 검색어 모두 삭제
    $('#miaps-search-keyword').val('').focus()
    $(this).hide()
    $('#_miaps_console > div').not('#miaps-debug-search-box').show()
  },
  logClear: function () {
    // clear all log
    $('#_miaps_console')
      .children()
      .not('#miaps-debug-search-box')
      .not('#_miaps_script')
      .remove()
  }
  /*scriptTextareaResize: function() {
			$("#miaps-script-command").css("height", "1px");
			$("#miaps-script-command").css("height", (20 + $("#miaps-script-command").prop("scrollHeight") + "px"));
		},
		execScript: function() {
			if (MiapsHybrid.MobilePlatform.Android()) {
				window.miapsexpression.run($("#miaps-script-command").val());
			} else if (MiapsHybrid.MobilePlatform.iPhone() || MiapsHybrid.MobilePlatform.iPad()) {
				alert("Not support yet.");
			}
		}*/
} //END MiapsHybrid

// script excutor test callback
/*window.run_callback = function (result) {
		if (result == null) return;
		console.log(result);
	}*/

$.extend($.expr[':'], {
  // contains 대소문자 구분없이 검색
  containsIN: function (elem, i, match, array) {
    return (
      (elem.textContent || elem.innerText || '')
        .toLowerCase()
        .indexOf((match[3] || '').toLowerCase()) >= 0
    )
  }
})

// 개발자가 모바일 장치에서 로그를 보기 위한 콘솔 로그 대체
if (window._tmdebug === true) {
  let _console =
    window.top.document.querySelector('#_miaps_console') ||
    MiapsHybrid.dom(window.top.document.getElementById('root'), 'div', {
      id: '_miaps_console',
      //className: 'modal', -- 타 회사의 className과 겹치는 경우가 많아서 삭제. 및 아래쪽  z-index를 2001에서 최대값인 2147483647로 수정. 2020.12.07 chlee
      className: '',
      style: {
        cssText:
          'position: fixed; left: 0; top: 0; right: 0; bottom: 0; background: white; ' +
          //style: {cssText: 'height: auto; min-height: 100%; padding-bottom: 40px; background: white; '
          'z-index: 2147483647; display: none; padding: .5em; color: black; font-family: monospace;' +
          'word-break: break-all; overflow-y: auto; -webkit-overflow-scrolling: touch; '
      }
    })

  // Add Search Box, clear button
  let searchDiv = MiapsHybrid.dom(_console, 'div', {
    id: 'miaps-debug-search-box'
  })
  let searchInput = MiapsHybrid.dom(searchDiv, 'input', {
    type: 'text',
    id: 'miaps-search-keyword',
    placeholder: 'search keyword',
    style: {
      cssText:
        'border: 0; padding: 7px 0; border-bottom: 1px solid #337AB7; width: 92%;'
    }
  })
  searchInput.addEventListener('keyup', MiapsHybrid.searchDebugKeyup)
  let searchClear = MiapsHybrid.dom(
    searchDiv,
    'span',
    {
      id: 'miaps-search-clear',
      style: {
        cssText:
          'position: absolute; right: 40px; top: 11px; bottom: 0; width: 18px; height: 18px; font-size: 18px; color: #ccc; background-color: #fff;'
      }
    },
    '✕'
  )
  searchClear.addEventListener('click', MiapsHybrid.searchDebugClear)
  $('#miaps-search-clear').toggle(Boolean($('#miaps-search-keyword').val()))
  // clear log
  let logClear = MiapsHybrid.dom(
    searchDiv,
    'span',
    {
      id: 'miaps-search-clear',
      style: {
        cssText:
          'position: absolute; right: 10px; top: 10px; bottom: 0; width: 26px; height: 26px; line-height: 26px; font-size: 18px; font-weight: bold; color: #fff; background-color: #337AB7; border-radius:50%; display: inline-block; text-align: center; margin-right: -3px;'
      }
    },
    'D'
  )
  logClear.addEventListener('click', MiapsHybrid.logClear)

  // 개발자용 스크립트 실행기
  /*var _script = MiapsHybrid.dom(_console, 'div', {
				id: '_miaps_script',
				className: '', // console보다 zindex하나 작음.
				style: {cssText: 'position: fixed; left: 0; right: 0; bottom: 0; background: white; '
					+ 'z-index: 2147483646; display: block; padding: .5em; font-family: Courier New;'
					+ 'word-break: break-all; overflow-y: auto; -webkit-overflow-scrolling: touch; border: solid 1px #000;'}
			});
		MiapsHybrid.dom(_script, 'div', null, 'MiAPS Simple Script Excutor');
		// Add Script Textarea, Exec button
		var scriptDiv= MiapsHybrid.dom(_script, 'div', {id: 'miaps-debug-script-box'});
		var scriptTextArea = MiapsHybrid.dom(scriptDiv, 'textarea', {
			rows: '1',
			id: 'miaps-script-command',
			name: 'miaps-script-command',
			style: { cssText: "width:100%;overflow:visible;"}});
		scriptTextArea.addEventListener('keyup', MiapsHybrid.scriptTextareaResize);
		MiapsHybrid.dom(scriptDiv, 'br');
		var execScript =  MiapsHybrid.dom(scriptDiv, 'button', { 
			id: 'miaps-script-exec',
			style: { cssText: "display: block; width: 100%; color: #fff; background-color: #337ab7; border-color: #2e6da4; padding: 6px 12px; margin-bottom: 0; font-size: 14px; font-weight: 400; line-height: 1.42857143; text-align: center; white-space: nowrap; vertical-align: middle; -ms-touch-action: manipulation; touch-action: manipulation; cursor: pointer; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; background-image: none; border: 1px solid transparent; border-radius: 4px;"}
		}, 'Exec Script');
		execScript.addEventListener('click', MiapsHybrid.execScript);
		*/

  //$(document).on('touchstart', function(e) {
  document.addEventListener('touchstart', (e) => {
    if (e.touches.length === 3) {
      if (_console.style.display === 'none') {
        _console.style.display = 'block'
        window.top.document.body.appendChild(_console) // 가장 마지막으로 이동
      } else {
        _console.style.display = 'none'
      }
    }
  })
  $(_console)
    .off('click')
    .on('click', 'div', function () {
      if (this.hasAttribute('data-stack')) {
        alert(this.getAttribute('data-stack'))
      }
    })

  let oldLog = console.log

  console.log = function () {
    let debugblock = MiapsHybrid.dom(_console, 'div', {
      style: { display: 'block' }
    })
    let item = MiapsHybrid.dom(debugblock, 'div', {
      style: { cssText: 'padding: .5em 0;' }
    })
    let stack = new Error().stack.split(/\n/)
    stack.shift() // 첫째줄 삭제
    item.setAttribute('data-stack', 'Log\n' + stack.join('\n'))
    let ct = new Date()
    MiapsHybrid.dom(
      item,
      'span',
      { style: { cssText: 'color:#bbb; margin-right: 1em;' } },
      new Date(ct - ct.getTimezoneOffset() * 60 * 1000).toISOString().slice(11)
    )
    MiapsHybrid.dom(debugblock, 'hr', {
      style: { cssText: 'border-top: solid 1px #ddd; border-bottom: none' }
    })

    let s = ''
    for (let i = 0; i < arguments.length; ) {
      let a = arguments[i++]
      if (a) {
        a =
          (typeof a === 'function' || typeof a === 'string'
            ? a
            : a.stack && a.message
            ? a.message
            : JSON.stringify(a)) + (i < arguments.length ? ', ' : '')
        //길이제한 추가
        a =
          a.length > MiapsHybrid.maxLogLength
            ? a.substring(0, MiapsHybrid.maxLogLength) + '...'
            : a
      }
      MiapsHybrid.dom(item, 'span', null, a)
      s += a
    }

    if (s !== '' && s.length > 0) {
      oldLog.call(console, s)
    }
  }
}

// window.onerror
if (window._tmdebug === true) {
  //} && (MiapsHybrid.isAndroid() || MiapsHybrid.isIOS())) {
  /*
		message: error message (string). Available as event (sic!) in HTML onerror="" handler.
		source: URL of the script where the error was raised (string)
		lineno: Line number where error was raised (number)
		colno: Column number for the line where the error occurred (number)
		error: Error Object (object)
		*/
  window.onerror = function (msg, url, lineNo, columnNo, error) {
    let string = msg.toLowerCase()
    let substring = 'script error'
    if (string.indexOf(substring) > -1) {
      console.log('Script Error: See Browser Console for Detail: ' + string)
    } else {
      let message = [
        'Message: ' + msg,
        'URL: ' + url,
        'Line: ' + lineNo,
        'Column: ' + columnNo,
        'Error object: ' + String(error.stack)
      ].join(' - ')

      console.log(
        message +
          ' - model: ' +
          MiapsHybrid.mh_model +
          ' - os: ' +
          MiapsHybrid.mh_os
      )
    }
    return false
  }
  /*
		var interactiveT;
		var completeT;
		window.addEventListener('load', (event) => {
			console.log('load\n');
			console.log (completeT - interactiveT);
			console.log(`seconds elapsed = ${Math.floor((completeT - interactiveT) / 1000)}`);
		});
		
		document.addEventListener('readystatechange', (event) => {
			console.log(`readystate: ${document.readyState}\n`);
			if (document.readyState == 'interactive') {
				interactiveT = new Date().getTime();
			} else if (document.readyState == 'complete') {
				completeT = new Date().getTime();
			}
		});

		document.addEventListener('DOMContentLoaded', (event) => {
			console.log(`DOMContentLoaded\n`);
		});
*/
}

// set server url
for (let idx = 0; idx < MiapsHybrid.serverInfo.length; idx++) {
  let tmpObj = MiapsHybrid.serverInfo[idx]
  if (
    window.location.pathname.indexOf(tmpObj.chkPath) === 0 &&
    tmpObj.runmode === window._miaps_runmode
  ) {
    // startsWith()
    MiapsHybrid.sendUrl = tmpObj.url
  }
}

// 에러 통신 시 사용할 모델명, OS버전을 미리 취득해 놓는다.
if (window._tmdebug === true && MiapsHybrid.isWebView()) {
  MiapsHybrid.mobile({ type: 'devicemodel', param: 'PC' }, function (data) {
    MiapsHybrid.mh_model = MiapsHybrid.parse(data).res
  })

  MiapsHybrid.mobile(
    { type: 'platformversion', param: 'Windows10' },
    function (data) {
      MiapsHybrid.mh_os = MiapsHybrid.parse(data).res
    }
  )
} else {
  MiapsHybrid.mh_model = ''
  MiapsHybrid.mh_os = ''
}

// hSaveValueKeys가 있으면 로그 한다.
if (MiapsHybrid.isPC()) {
  let _tf = localStorage.getItem('save-value-keys')
  if (_tf != null && _tf !== 'undefined') {
    MiapsHybrid.hSaveValueKeys = _tf
  }
}

let _gContextNm = MiapsHybrid.getContextPath()
let miaps = MiapsHybrid
window.miaps = miaps
export {
  miaps
  /*
Android-Toast
(c) 2013-2014 Jad Joubran
*/
}
;(function () {
  function Android_Toast(options) {
    var position
    this.timeout_id = null
    this.duration = 3000
    this.content = ''
    this.position = 'bottom'

    if (!options || typeof options != 'object') {
      return false
    }

    if (options.duration) {
      this.duration = parseFloat(options.duration)
    }
    if (options.content) {
      this.content = options.content
    }

    if (options.position) {
      position = options.position.toLowerCase()
      if (position === 'top' || position === 'bottom') {
        this.position = position
      } else {
        this.position = 'bottom'
      }
    }
    this.show()
  }

  Android_Toast.prototype.show = function () {
    if (!this.content) {
      return false
    }
    clearTimeout(this.timeout_id)

    var body = document.getElementsByTagName('body')[0]

    var previous_toast = document.getElementById('android_toast_container')
    if (previous_toast) {
      body.removeChild(previous_toast)
    }

    var classes = 'android_toast_fadein'
    if (this.position === 'top') {
      classes = 'android_toast_fadein android_toast_top'
    }

    var toast_container = document.createElement('div')
    toast_container.setAttribute('id', 'android_toast_container')
    toast_container.setAttribute('class', classes)
    body.appendChild(toast_container)

    var toast = document.createElement('div')
    toast.setAttribute('id', 'android_toast')
    toast.innerHTML = this.content
    toast_container.appendChild(toast)

    this.timeout_id = setTimeout(this.hide, this.duration)
    return true
  }

  Android_Toast.prototype.hide = function () {
    var toast_container = document.getElementById('android_toast_container')

    if (!toast_container) {
      return false
    }

    clearTimeout(this.timeout_id)

    toast_container.className += ' android_toast_fadeout'

    function remove_toast() {
      var toast_container = document.getElementById('android_toast_container')
      if (!toast_container) {
        return false
      }
      toast_container.parentNode.removeChild(toast_container)
    }

    toast_container.addEventListener('webkitAnimationEnd', remove_toast)
    toast_container.addEventListener('animationEnd', remove_toast)
    toast_container.addEventListener('msAnimationEnd', remove_toast)
    toast_container.addEventListener('oAnimationEnd', remove_toast)

    return true
  }

  //expose the Android_Toast object to Window
  window.Android_Toast = Android_Toast
})()
